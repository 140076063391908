import React from "react"
import { Link } from "gatsby"
import showcase1 from "../../images/client/Rectangle 1061.png"
import showcase2 from "../../images/client/hatsun.png"
import showcase3 from "../../images/client/titanwatch.png"
import showcase4 from "../../images/client/banner.png"
import showcase5 from "../../images/client/supervalue.png"
import showcase6 from "../../images/client/shareauto.png"
import showcase7 from "../../images/client/watch.png"
import showcase8 from "../../images/client/wall-photos.png"
import showcase9 from "../../images/client/fm.png"
import dotts from "../../images/svg/dot-bg.svg"

const showcase = () => {
  return (
    <>
      <section id="venue">
        <div
          className="container venue-gallery-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <img
            src={dotts}
            className="bg-showshase d-none d-lg-block "
            alt=""
            data-aos="zoom-in"
          />
          <span
            className="slider-hero"
            data-animation="fadeInLeft"
            data-delay="0.1s"
          >
            OUR PORTFOLIO
          </span>

          <p className="section-headersecond">CREATIVE SHOWCASE</p>

          <p className="started-testsecond">
            showcasing our projects that inspire usto more
          </p>

          <div className="row no-gutters">
            <div className="col-lg-4 col-md-4">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase1} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase2} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase3} alt="" className="img-fluid position-relative" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase4} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase5} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-2">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase6} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-2">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase7} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-2">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase8} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-2">
              <div className="venue-gallery">
                <Link href="/" className="venobox" data-gall="venue-gallery">
                  <img src={showcase9} alt="" className="img-fluid" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default showcase
