import React from "react"
import Slider from "./slider"
import Experience from "./experience"
import Brand from "./brand"
import Digital from "./digital"
import Services from "./services"
import Clients from "./clients"
import Enquiry from "./enquiry"
import Showcase from "./showcase"

const homePage = () => {
  return (
    <>
      <Slider />
      <Experience />
      <Brand />
      <Showcase />
      <Digital />
      <Services />
      <Clients />
      <Enquiry />
    </>
  )
}

export default homePage
