import React from "react"

import Layout from "../components/layout"
import Homepage from "../components/home/index"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Best Digital &amp; Print Media Company in Chennai - Mass Media Creatives"
      desc="We believe in enriching brands through our ingenious digital branding and printing services across various categories of brands"
      keywords="Print Media Company in Chennai, Printing services in chennai, Graphic Design, Photography  &amp; videography"
    />
    <Homepage />
  </Layout>
)

export default IndexPage
