import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import imageone from "../../images/svg/services-01.svg"
import imagetwo from "../../images/svg/services-02.svg"
import imagethree from "../../images/svg/services-06.svg"
import imagefour from "../../images/svg/services-03.svg"
import imagefive from "../../images/svg/services-05.svg"
import imagesix from "../../images/svg/services-04.svg"
import imageseven from "../../images/svg/print.svg"

const brand = () => {
  return (
    <>
      <section className="help-grow ">
        <div className="container py-4" data-aos="fade-up">
          <div className="row">
            <div className="col-12 col-lg-5  image-flip">
              <span
                className="slider-hero"
                data-animation="fadeInLeft"
                data-delay="0.1s"
              >
                wHAT WE DO
              </span>
              <p className="help-text">
                HOW WE <br />
                HELP YOU <br />
                GROW YOUR <br /> Brand
              </p>
              <p className="your-game pt-4">
                Be on top of your game <br /> with impeccable digital <br />{" "}
                branding solutions
              </p>
              <p className="text-story pt-4">
                This story back in 1986 when we registered <br />
                'Chitralaya Arts' the word 'Chitra' means pictures.
                <br />
                We are equipped with over 34 years.{" "}
              </p>
            </div>
            <div className="col-12 col-lg-7 ">
              <div className="row">
                <div className="col-sm-6  content-item my-3 card-brand">
                  <div className="card h-100 border-primary ">
                    <div className="card-body text-primary card-style">
                      <h5 className="card-titleone">Graphic Design</h5>
                      <div className="brand-icons">
                        <img
                          src={imageone}
                          alt="imageone"
                          className="brand-design"
                        ></img>
                        <Link
                          to="/services/graphic-design"
                          className="stretched-link"
                        ></Link>
                        <FontAwesomeIcon
                          className="brandhover-icon"
                          icon={faArrowRight}
                          size="1x"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item my-3 card-brand">
                  <div className="card  h-100 border-secondary ">
                    <div className="card-body text-secondary card-styleone">
                      <h5 className="card-titletwo">
                        Photography &amp; Videography
                      </h5>
                      <div className="brand-icons">
                        <img
                          src={imagetwo}
                          alt="imagetwo"
                          className="brand-design"
                        ></img>
                      </div>

                      <Link
                        to="/services/photography-videography"
                        className="stretched-link"
                      ></Link>

                      <FontAwesomeIcon
                        className="brandhover-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item my-3 card-brand">
                  <div className="card  h-100 border-success ">
                    <div className="card-body text-success card-styletwo">
                      <h5 className="card-titlethree">
                        Website &amp; Mobile Application development
                      </h5>
                      <div className="brand-icons">
                        <img
                          src={imagethree}
                          alt="imagethree"
                          className="brand-design"
                        ></img>
                      </div>

                      <Link
                        to="/services/website-mobile-app-development"
                        className="stretched-link"
                      ></Link>

                      <FontAwesomeIcon
                        className="brandhover-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item my-3 card-brand">
                  <div className="card h-100 border-danger  ">
                    <div className="card-body text-danger card-stylethree">
                      <h5 className="card-titlefour">Digital Marketing</h5>
                      <div className="brand-icons">
                        <img
                          src={imagefour}
                          alt="imagefour"
                          className="brand-design"
                        ></img>
                      </div>{" "}
                      <Link
                        to="/services/digital-marketing"
                        className="stretched-link"
                      ></Link>
                      <FontAwesomeIcon
                        className="brandhover-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item my-3 card-brand">
                  <div className="card h-100 border-warning ">
                    <div className="card-body text-warning card-stylefour">
                      <h5 className="card-titlefive">
                        Advertishing &amp; Promotional MIX
                      </h5>
                      <div className="brand-icons">
                        <img
                          src={imagefive}
                          alt="imagefive"
                          className="brand-design"
                        ></img>
                      </div>

                      <Link
                        to="/services/advertising-promotional"
                        className="stretched-link"
                      ></Link>

                      <FontAwesomeIcon
                        className="brandhover-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item my-3 card-brand">
                  <div className="card h-100 border-info  ">
                    <div className="card-body text-info card-stylefive">
                      <h5 className="card-titlesix">
                        Events &amp; <br /> Exhibitions
                      </h5>
                      <div className="brand-icons">
                        <img
                          src={imagesix}
                          alt="imagesix"
                          className="brand-design"
                        ></img>
                      </div>{" "}
                      <Link
                        to="/services/events-exhibitions"
                        className="stretched-link"
                      ></Link>
                      <FontAwesomeIcon
                        className="brandhover-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item my-3 card-brand">
                  <div className="card h-100 border-dark   ">
                    <div className="card-body text-dark card-stylesix">
                      <h5 className="card-titleseven">Printing Services</h5>
                      <div className="brand-icons">
                        <img
                          src={imageseven}
                          alt="imageseven"
                          className="brand-design"
                        ></img>
                      </div>

                      <Link
                        to="/services/printing-services"
                        className="stretched-link"
                      ></Link>

                      <FontAwesomeIcon
                        className="brandhover-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 content-item position-relative">
                  <div className="card-body text-muted">
                    <p className="card-text discover-mode">
                      <Link to="/services" className="stretched-link">
                        Discover More{" "}
                        <FontAwesomeIcon
                          className="brandhover-icons"
                          icon={faArrowRight}
                          size="1x"
                        />{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default brand
