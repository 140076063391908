import React from "react"

import chennai from "../../images/Rectangle 1038.png"
import bangalore from "../../images/Rectangle 1040.png"

import Line from "./line"

const enqiry = () => {
  return (
    <>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title text-center">
            <h4 className="enqiry-text">
              You can talk to us about features, trails,pricing or anything
              else!
              <br />
              Our team is here to answer all questions !
            </h4>
            <p className="enquiry-content pt-4 ">
              Send your inquiry through mail and one our stadd members will get
              in touch with you within 24 hours.
            </p>
          </div>

          <div></div>

          {/* <div className="row mt-5">
            <div className="col-lg-12 mt-5 mt-lg-0">
              <form className="php-email-form">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="*Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="phone"
                      name="number"
                      className="form-control"
                      id="number"
                      placeholder="*Phone No"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 10 number"
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="*Email Address"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <div className="validate"></div>
                  </div>

                  <div className="col-md-8 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="message"
                      id="message"
                      placeholder="Message"
                      data-rule="minlen:160"
                      data-msg="Please enter at least 160 chars of subject"
                    />
                    <div className="validate"></div>
                  </div>

                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <button
                      type="button"
                      className="col-md-12 btn btn-primary enquiry-submit"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </section>
      <Line />
      <section id="location" className="section-with-bg">
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6 col-md-6">
              <div className="hotel">
                <div className="hotel-img">
                  <img src={chennai} alt="Hotel 1" className="img-location" />
                  <span className="location_gallery">CHENNAI</span>
                </div>

                <p className="text-center location-phone">
                  <b>M : +91 72999 22135</b>
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="hotel">
                <div className="hotel-img">
                  <img src={bangalore} alt="Hotel 2" className="img-location" />
                  <span className="location_gallery">Bangalore</span>
                </div>

                <p className="text-center location-phone">
                  <b>M : +91 80414 70722</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default enqiry
