import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from "gatsby"
import parrot from "../../images/parrot.png"
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SliderHome = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }
  return (
    <>
      {" "}
      <section className="home-slider position-relative ">
        <div className="container-fluid">
          <img src={parrot} alt="" className="parrot-right d-none d-lg-block" />
          <div className="slider-bg-text">Mass Media Creatives</div>
          <div className="row">
            <div className="col-12 col-sm-12 mt-lg-0">
              <Slider {...settings}>
                <div className="carousel-block active">
                  <div className="slide-content">
                    <div className="header-text">
                      <h2 className="header-size">
                        Creating Original <b className="fw-bold">Brands</b>{" "}
                        <br />
                        through impactful differentiation
                      </h2>
                      <p className="slider-para">
                        {" "}
                        We help you create a unique brand identity by catering
                        to all
                        <br />
                        your digital branding &amp; printing needs
                      </p>
                    </div>
                    <div className="slider-text">
                      <Link to="/">
                        <FontAwesomeIcon
                          icon={faPlayCircle}
                          size="1x"
                          className="slider-play"
                        />{" "}
                        &nbsp; Watch Promo Video
                      </Link>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SliderHome
