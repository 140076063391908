import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import hellofm from "../../images/svg/branding.svg"
import hellfs from "../../images/svg/strategy.svg"
import hellofl from "../../images/svg/design.svg"
import hellofg from "../../images/svg/printing.svg"
import wavelines from "../../images/svg/wave-rebuild123.png"
import waveflow from "../../images/svg/wave.svg"

const digital = () => {
  return (
    <>
      <section className="icon-boxes position-relative">
        <h3 className="vl">Mass Media Creatives</h3>
         <img src={wavelines} alt="" className="d-none d-lg-block waves-lines" /> 
        <img src={waveflow} alt="" className="d-none d-lg-block waves-flow" />

        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 col-lg-6 pt-4 pt-lg-0 image-flip">
              <span
                className="slider-hero"
                data-animation="fadeInLeft"
                data-delay="0.1s"
              >
                Digital Experience
              </span>
              <p className="section-headerthree">
                BE A PART OF THE DIGITAL WAVE
              </p>

              <p className="started-textthree">CUTTING-EDGE BRAND EXPERIENCE</p>
          
              <p className="story-textone">
                Our aim is to elevate your brand in the market by offering an
                extensive range of value added services. We utilize the latest
                technology to convert your idea into brand reality.Share your
                concept and will get back to you with apt and affordable digital
                strategies.
              </p>
           
            </div>

            <div className="col-lg-6 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch ">
              <div className="content d-flex flex-column justify-content-center ">
                <div className="row">
                  <div
                    className="col-md-6  d-flex align-items-stretch  flex-column stageone"
                    data-aos="fade-up"
                  >
                    <div className="icon-box branding ">
                      <div className="icon-brands">
                        <img src={hellofm} alt="hellofm"></img>
                      </div>
                      <h4 className="title pt-4">
                        <a href="/">Branding</a>
                      </h4>
                      <p className="description">
                        Our collaborative approach builds brands and apps worth
                        talking adobut
                      </p>
                      <FontAwesomeIcon
                        className="digitalarrow-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                    <div className="icon-box printing">
                      <div className="icon-brands">
                        <img src={hellofg} alt="hellofg"></img>
                      </div>
                      <h4 className="title pt-4">
                        <a href="/">Printing</a>
                      </h4>
                      <p className="description">
                        Our collaborative approach builds brands and apps worth
                        talking adobut
                      </p>
                      <FontAwesomeIcon
                        className="digitalarrow-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-6 d-flex align-items-stretch mb-5 flex-column"
                    data-aos="fade-up"
                  >
                    <div className="icon-box strategy">
                      <div className="icon-brands">
                        <img src={hellofl} alt="hellofl"></img>
                      </div>
                      <h4 className="title pt-4">
                        <a href="/">Strategy</a>
                      </h4>
                      <p className="description">
                        Our collaborative approach builds brands and apps worth
                        talking adobut
                      </p>
                      <FontAwesomeIcon
                        className="digitalarrow-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                    <div className="icon-box design">
                      <div className="icon-brands">
                        <img src={hellfs} alt="hellfs"></img>
                      </div>
                      <h4 className="title pt-4">
                        <a href="/">Design</a>
                      </h4>
                      <p className="description">
                        Our collaborative approach builds brands and apps worth
                        talking adobut
                      </p>
                      <FontAwesomeIcon
                        className="digitalarrow-icon"
                        icon={faArrowRight}
                        size="1x"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default digital
