import React from "react"

import years from "../../images/38.png"
import birds from "../../images/svg/bird-bg.svg"

const experience = () => {
  return (
    <>
      <section className="position-relative">
        <img className="bird-bg" src={birds} alt="" />
        <h3 className="vr">Mass Media Creatives</h3>

        <div className="container py-4" data-aos="fade-up">
          <div className="row">
            <div className="col-12 col-lg-6 pt-lg-0 image-flip">
              <img className="sample-image " src={years} alt="customers " />
              <p className="digital">
                Digital branding <br />
                &amp; printing
              </p>
            </div>
            <div className="col-12 col-lg-6 ">
              <span
                className="slider-hero"
                data-animation="fadeInLeft"
                data-delay="0.1s"
              >
                wHO WE ARE
              </span>
              <div className="section-header section-title">
                <p>
                  3 decades <br /> of experience
                </p>
              </div>
              <div>
                <p className="package started-text">
                  Started with a vision of establishing business brands
                  <br />
                  and driven with the thought of innovation.
                </p>
                <p className="story-text">
                  This story goes back in 1986 when we registered ‘Chitralaya
                  Arts’ the word ‘Chitra’ means pictures. We are equipped with
                  over 34 years of experience and expertise in offering print
                  media and digital media solutions to our customers. With some
                  more improvisations to our digital media sphere, we renamed
                  our company to MASS MEDIA CREATIVES in the year 2019. We
                  re-defined our business goal with the vision of catering to a
                  wider audience across the globe and across all industries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default experience
